/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState } from 'react';
import { Row, Col, Table, Form, Popconfirm } from 'antd';
import { UilTrashAlt, UilDownloadAlt } from '@iconscout/react-unicons';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { Action, InputTableStyleWrapper } from '../style';
import { useInputsContext } from '../context';
import ModalBatchs from '../modal/modalBatchs';
import { deleteBatch, downloadUpload } from '../../../services/inputs';
import { TableWrapper } from '../../style';
import { Button } from '../../../components/buttons/buttons';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { handleError } from '../../../utility/utility';

function InputsBatchs() {
  const { errorMessage, successMessage, loadingTable, dataBatchs, refetchTableBatchs, state } = useInputsContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [removedFiles, setRemovedFiles] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [errors, setErrors] = useState([]);

  const removeBatch = useMutation(deleteBatch, {
    onError: (error) => {
      if (error) {
        return errorMessage(handleError(error, dispatch, navigate));
      }
    },
    onSuccess: (res) => {
      if (res?.id) {
        onCancel();
        refetchTableBatchs();
        successMessage(`${t('batch')} ${t('deletedSuccessfully')}`);
      } else {
        return errorMessage(handleError(res, dispatch, navigate));
      }
    },
  });

  const showModal = () => {
    setVisible(true);

    form.setFieldsValue({});
  };

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
    setFileList([]);
    setRemovedFiles([]);
    setErrors({});
  };

  const onHandleDelete = (id) => {
    removeBatch.mutate({
      id,
      keycode: state?.reportType,
    });
  };

  const handleDownload = async (attachFileId) => {
    const result = await downloadUpload(attachFileId);
    return result;
  };

  const batchsTableData = [];
  if (Array.isArray(dataBatchs)) {
    dataBatchs.map((item) => {
      const { id, records, updatedBy, updatedDate, batch } = item;
      const filesToDownload = [];

      if (batch !== null) {
        if (Array.isArray(batch?.r_documents) && batch?.r_documents.length) {
          batch?.r_documents?.forEach((doc) => {
            if (Array.isArray(doc?.doc_file) && doc?.doc_file.length) {
              doc?.doc_file?.forEach((file) => {
                filesToDownload.push({ url: file.url, name: file.name });
              });
            }
          });
        }
      }

      return batchsTableData.push({
        key: id,
        id,
        batchs: (
          <>
            <div className="user-info">
              <span className="user-name">{updatedBy} </span>
            </div>
            <div className="user-info">
              <span className="user-designation">{updatedDate}</span>
            </div>
          </>
        ),
        records,
        download: (
          <Action className="table-actions">
            <Button
              title={
                Array.isArray(filesToDownload) && !filesToDownload.length
                  ? ''
                  : `${filesToDownload.length} ${t('files')}`
              }
              onClick={
                Array.isArray(filesToDownload) && !filesToDownload.length ? null : () => handleDownload(filesToDownload)
              }
              className="btn-icon"
              type="primary"
              to="#"
              shape="circle"
              style={
                Array.isArray(filesToDownload) && !filesToDownload.length ? { color: '#b7b7b7' } : { color: '#008c00' }
              }
            >
              <UilDownloadAlt />
            </Button>
          </Action>
        ),
        delete: (
          <Action className="table-actions">
            <Popconfirm
              title={t('confirmDeleteBatch')}
              onConfirm={() => onHandleDelete(id)}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <Button title={t('delete')} className="btn-icon" type="primary" to="#" shape="circle">
                <UilTrashAlt color="red" />
              </Button>
            </Popconfirm>
          </Action>
        ),
      });
    });
  }
  const batchsTableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('batchs'),
      dataIndex: 'batchs',
      key: 'batchs',
    },
    {
      title: t('reportedTrips'),
      dataIndex: 'records',
      key: 'records',
    },
    {
      title: <UilDownloadAlt color="#b7b7b7" size={20} />,
      dataIndex: 'download',
      key: 'download',
      width: '20px',
      align: 'center',
    },

    {
      title: <UilTrashAlt color="red" size={20} />,
      dataIndex: 'delete',
      key: 'delete',
      width: '20px',
      align: 'center',
    },
  ];
  return (
    <>
      <Row justify="end" style={{ marginBottom: 20 }}>
        <Button onClick={showModal} className="btn-add_new" size="default" type="primary" key="1">
          <Link to="#">+ {` ${t('new')} ${t('batch')}`}</Link>
        </Button>
      </Row>
      <Row gutter={15}>
        <Col xs={24}>
          <Cards headless>
            <InputTableStyleWrapper>
              <div className="inputs-table">
                <TableWrapper className="table-responsive">
                  <Table
                    dataSource={batchsTableData}
                    // expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
                    columns={batchsTableColumns}
                    pagination={{
                      defaultPageSize: process.env.REACT_APP_DEFAULTPAGESIZE,
                      total: batchsTableData.length,
                      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    }}
                    loading={loadingTable}
                  />
                </TableWrapper>
              </div>
            </InputTableStyleWrapper>
          </Cards>
        </Col>
      </Row>
      <ModalBatchs
        visible={visible}
        form={form}
        setFileList={setFileList}
        fileList={fileList}
        setRemovedFiles={setRemovedFiles}
        removedFiles={removedFiles}
        onCancel={onCancel}
        refetch={refetchTableBatchs}
        errors={errors}
        setErrors={setErrors}
      />
    </>
  );
}

export default InputsBatchs;
