import Styled from 'styled-components';

const Action = Styled.div`
  .active{
    color: #FA8B0C;
  }
`;
const DashboardPageheaderStyle = Styled.div`
  .ant-page-header-heading-title{
    margin-right: 0;
    padding-right: 0;
    &:after{
      display: none;
    }
  }
  .ant-select .ant-select-selection-search-input{
    border-radius: 6px;
  }
`;
const DashboardCardWrapper = Styled.div`
  position: relative;
  background-color: ${({ theme }) => theme[theme.mainContent]['white-background']} !important;
  border-radius:16px;
  .ant-menu {
    border: 0px;
  }


  .dashboard-card{
    figure{
      text-align: center;
      margin: 0;
      img{
        border-radius: 50%;
      }
      figcaption{
        border-bottom: 2px solid #008c00;
        h3{
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 0;
          color: #008c00;
          font-weight:bold
        }
        span{
          color: ${({ theme }) => theme[theme.mainContent]['gray-light-text']};
        }
      }
    }
    .dropdown-scope{
      background-color: ${({ theme }) => theme[theme.mainContent]['scope-bg']} !important;
      padding: 10px;
      margin-top: 20px;
      border-top: 1px solid #008c00};
      label {
      color: ${({ theme }) => theme[theme.mainContent]['dark-text']} ;
      }
      ul{
        li{
          display: flex;
          align-items: center;
          color: ${({ theme }) => theme[theme.mainContent]['gray-light-text']};
          &:not(:last-child){
            margin-bottom: 14px;
          }
          svg{
            width: 16px;
            height: 16px;
            ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 12px;
            color: ${({ theme }) => theme[theme.mainContent]['gray-light-text']};
          }
        }
      }
    }
  }
`;
export { Action, DashboardPageheaderStyle, DashboardCardWrapper };
