/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState } from 'react';
import { Row, Col, Form, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { AddModal } from '../../style';
import { Button } from '../../../components/buttons/buttons';
import { Modal } from '../../../components/modals/antd-modals';
import UploadDragger from '../../../components/upload/Upload';
import { postBatchs } from '../../../services/inputs';
import { useInputsContext } from '../context';
import { handleError } from '../../../utility/utility';

function ModalBatchs({
  visible,
  form,
  setFileList,
  fileList,
  setRemovedFiles,
  removedFiles,
  onCancel,
  title = '',
  errors,
  setErrors,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [trips, setTrips] = useState([]);
  const [fileCsv, setFileCsv] = useState('');

  const { errorMessage, successMessage, state, activeContract, contracts, tripsOptions, refetchTableBatch } =
    useInputsContext();

  function convertXLSXToArray(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = 'TRIPS';
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, range: 2 });
        resolve(jsonData);
      };

      reader.onerror = (e) => {
        reject(e);
      };

      reader.readAsArrayBuffer(file);
    });
  }

  function readCsvFile(file) {
    setErrors([]);
    const iatas = [];
    const iatasErrors = [];

    const lineErrors = [];
    convertXLSXToArray(file)
      .then((arrayData) => {
        let fileString = '';
        arrayData.map((line, indexLine) => {
          if (line.length) {
            for (let indexColumn = 0; indexColumn < line.length; indexColumn++) {
              if (indexColumn === 0) {
                const numberColumn = Number(line[indexColumn]);
                if (!numberColumn) {
                  lineErrors.push({ error: t('errorFirstColunmCsv'), line: indexLine + 3, column: indexColumn + 1 });
                } else {
                  iatas.push({ line: indexLine + 3, tickets: line[indexColumn] });
                  iatasErrors.push({ line: indexLine + 3, tickets: line[indexColumn] });
                  if (indexLine === 0) {
                    fileString += `${line[indexColumn]},`;
                  } else {
                    fileString += `\n${line[indexColumn]},`;
                  }
                }
              } else {
                const trip = tripsOptions.find((value) => value.label === line[indexColumn]);
                if (trip) {
                  const valuePrevious = line[indexColumn - 1] ?? false;
                  const index = iatas.map((value) => value.line).indexOf(indexLine + 3);
                  const arrayTripsErrors = iatasErrors[index]?.trips ?? [];

                  if (line[indexColumn] === valuePrevious) {
                    iatasErrors[index].trips = [...arrayTripsErrors, trip.id];
                    return lineErrors.push({
                      error: t('fieldEqualPrevious'),
                      line: indexLine + 3,
                      column: indexColumn + 1,
                    });
                  }

                  if (index !== -1) {
                    const arrayTrips = iatas[index]?.trips ?? [];
                    iatas[index].trips = [...arrayTrips, trip.id];
                    iatasErrors[index].trips = [...arrayTripsErrors, trip.id];
                    if (indexColumn + 1 === line.length) {
                      fileString += `${line[indexColumn]}`;
                    } else {
                      fileString += `${line[indexColumn]},`;
                    }
                  }
                } else {
                  if (!line[indexColumn] && !line[indexColumn + 1]) {
                    return;
                  }
                  if (!line[indexColumn]) {
                    lineErrors.push({
                      error: t('fieldEmpty'),
                      line: indexLine + 3,
                      column: indexColumn + 1,
                    });
                  } else {
                    lineErrors.push({
                      error: t('iataNotExist'),
                      line: indexLine + 3,
                      column: indexColumn + 1,
                    });
                  }
                }
              }
            }
          }
        });
        iatasErrors.map((item) => {
          if (item?.trips?.length < 2) {
            lineErrors.push({
              error: t('minTwoAirports'),
              line: item.line + 2,
            });
          }
        });
        setTrips([...iatas]);
        setErrors([...lineErrors]);
        setFileCsv(fileString);
      })
      .catch((error) => {
        console.error(error);
      });

    // const reader = new FileReader();
  }

  const createBatchs = useMutation(postBatchs, {
    onError: (error) => {
      if (error) {
        return errorMessage(handleError(error, dispatch, navigate));
      }
    },
    onSuccess: (res) => {
      if (res) {
        onCancel();
        refetchTableBatch();
        successMessage(`${t('batch')} ${t('createSuccessfully')}`);
      } else {
        return errorMessage(handleError(res, dispatch, navigate));
      }
    },
  });

  const handleSubmit = async () => {
    if (!fileList?.length) {
      return errorMessage(t('sendFile'));
    }
    if (errors.length > 0) {
      return;
    }
    const newTrips = trips.map((item) => {
      return { trips: item.trips, tickets: item.tickets };
    });
    const record = {
      keycode: state?.reportType,
      data: {
        year: activeContract?.year,
        contract: activeContract?.id,
        company: contracts?.company?.id,
        unit: state?.unit,
        updater_user_profile: activeContract?.userprofile_id,
        trips: newTrips,
        fileCsv,
      },
    };
    createBatchs.mutate(record);
  };

  function handleErrorCSV() {
    return errors.map((item) => {
      const column = item.column ? `, ${t('column')} ${item.column}` : '';
      return <div>{`${t('errorIn')} ${t('line')} ${item.line} ${column}: ${item.error}`}</div>;
    });
  }

  return (
    <>
      <Modal
        width="800px"
        type="primary"
        title={`${t('new')} ${t('batch')} ${title}`}
        visible={visible}
        footer={null}
        onCancel={onCancel}
        maskClosable={false}
      >
        <AddModal>
          <div className="project-modal">
            <Form style={{ width: '100%' }} form={form} onFinish={handleSubmit}>
              <Row>
                <Col span={24}>
                  <Form.Item label={`${t('select')} ${t('file')}`} name="doc_file">
                    <UploadDragger
                      setFileList={setFileList}
                      fileList={fileList}
                      setRemovedFiles={setRemovedFiles}
                      removedFiles={removedFiles}
                      isBatch
                      readCsvFile={readCsvFile}
                      accept=".xlsx"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {errors.length > 0 && (
                <Alert
                  message={t('fileError')}
                  description={handleErrorCSV()}
                  type="error"
                  closable
                  style={{ margin: '10px 0px' }}
                />
              )}

              <Row justify="end">
                <div className="text-right" style={{ marginRight: 10 }}>
                  <Button type="danger" outlined onClick={() => onCancel()}>
                    {t('cancel')}
                  </Button>
                </div>
                <div className="add-input-bottom text-right">
                  <Button htmlType="submit" type="primary">
                    {t('submit')}
                  </Button>
                </div>
              </Row>
            </Form>
          </div>
        </AddModal>
      </Modal>
    </>
  );
}

export default ModalBatchs;
